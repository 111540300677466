import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: "Index",
            component: () => import('@/views/index'),
        },
        {
            path: '/en',
            name: "English",
            component: () => import('@/views/english/index'),
        },
        {
            path: '/report/:language/:id',
            name: "Report",
            component: () => import('@/views/report/index'),
        },
        {
            path: '/single/:language/:id',
            name: "Single",
            component: () => import('@/views/single/index'),
        },
        {
            path: '/special/:language/:id',
            name: "Special",
            component: () => import('@/views/special/index'),
        },
        {
            path: '/equity/:language',
            name: "Equity",
            component: () => import('@/views/equity/index'),
        },
        {
            path: "*",
            meta: {
                title: '404'
            },
            component: () => import('@/views/errors/error404.vue'),
        },
        {
            path: "/stop",
            meta: {
                title: '暂停服务'
            },
            component: () => import('@/views/errors/errorStop.vue'),
        },
    ],
});

export default router;