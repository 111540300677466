<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    // 禁止右键菜单
    document.addEventListener('contextmenu', this.handleContextMenu);
  },
  beforeUnmount() {
    // 清理事件监听
    document.removeEventListener('contextmenu', this.handleContextMenu);
  },
  methods: {
    handleContextMenu(e) {
      e.preventDefault();
    },
  }
}
</script>

<style lang="scss">
@import "@/styles/index.scss";
@import "styles/iconfont.css";
#app {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input, textarea {
    -webkit-user-select: auto;
    user-select: auto;
  }
}
</style>
